html {
  box-sizing: border-box;
  border-top: 5px solid #56b4c7;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  color: #333;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
