html,
body {
  margin: 0;
}

.Login__Right__Container__SahLogo {
  position: absolute;
  top: 2em;
  right: 2em;
}

.Login {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.Login__Left__Container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #56b4c7;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.Login__Left__Container__Background__Icon {
  position: absolute;
  right: 0;
  transform: translateX(50%);
}

.Login__Left__Features__Container {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.Login__Left__Feature {
  display: flex;
  justify-content: left;
  align-items: center;
  color: rgb(255, 255, 255);
  font-weight: bold;
  margin-top: 1em;
  font-size: 1.6em;
}

.Login__Left__Feature--icon {
  margin-right: 1.5em;
}

.Login__Right__Container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.Login__Right__Icon {
  margin-bottom: 4em;
}

.Login__Right__Intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
}

.Login__Right__Title,
.Login__Right__SubTitle {
  color: #333;
  text-align: center;
}

.LogIn__Right__Button {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  width: 240px;
  font-weight: bold;
  background-color: #56b4c7;
  border-radius: 4px;
  border: 1px solid #dadada;
  padding: 0.5em;
  text-decoration: none;
  color: #ffffff;
  font-size: 20px;
}

.LogIn__Right__Button:hover {
  background-color: #3ba1b5;
}

@media only screen and (max-width: 700px) {
  .Login {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
  }

  .Login__Left__Container {
    height: 30vh;
    padding: 4em 0em;
    margin-bottom: 2em;
  }

  .Login__Right__Container {
    height: 100vh;
    padding: 4em 0em;
  }

  .Login__Left__Container__Background__Icon {
    position: absolute;
    right: 0;
    transform: translateX(50%);
    width: 300px;
    height: 300px;
  }

  .Login__Right__Icon {
    margin-bottom: 0;
  }
}

.Login__Footer {
  position: fixed;
  text-align: center;
  bottom: 0;
  font-size: 0.8em;
  background-color: #fff;
  border: 1px solid #efefef;
  color: rgb(101, 119, 134);
  width: 100%;
  z-index: 1;
  padding: 12px;
}
